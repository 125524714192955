import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actionCreators as StudnetCreator } from "../../store/student";
import { Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { PrintGrades } from '../../utils/helperFunc';
import { Translate } from '../../helpers/Translate';
import { Link } from "react-router-dom";
import Line from "./Chart/Line";
import { formatDateTime as format } from "../../helpers/func";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid } from "@mui/material";
import Select from 'react-select';
import { components } from 'react-select';

const primary_clr = "#007bff";
const brdr_txt = "10px solid";
const homeTab = "homeTab";
const redXsize = 10;
const icon_arr = [
    "copy",
    "newspaper",
    "scroll",
    "paste",
    "map",
    "search",
    "flag",
    "user",
    "check",
    "image",
    "phone",
    "bars",
    "envelope",
    "star",
    "comment",
    "file",
    "bell",
    "clipboard",
    "bolt",
    "pen",
    "umbrella",
    "gift",
    "list",
    "gear",
    "inbox",
    "rotate-right",
    "lock",
    "headphones",
    "barcode",
    "tag",
    "book",
    "bookmark",
    "camera",
    "font",
    "share",
    "minus",
    "plus",
    "video",
    "fire",
    "eye",
    "eye-slash",
    "plane",
    "magnet",
    "folder",
    "folder-open",
    "money-bill",
    "code",
    "gears",
    "thumbtack",
    "key",
    "comments",
    "thumbs-down",
    "thumbs-up",
    "globe",
    "city",
    "tree",
    "wifi",
    "paint-roller",
    "sliders",
    "address-book",
    "compass",
    "briefcase",
    "flask",
    "hashtag",
    "brush",
    "layer-group",
    "palette",
    "binoculars",
    "snowflake",
    "handshake",
    "users",
    "business-time",
    "feather",
    "sun",
    "link",
    "pen-fancy",
    "landmark",
    "quote-left",
    "hand-holding-heart",
    "info",
    "user-minus",
    "calendar",
    "clock",
    "handshake-slash",
    "passport",
    "question",
    "street-view",
    "hourglass-start",
    "file-excel",
    "network-wired",
    "home",
];
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: '100%', // Set the width of the container to 80%
    }),
    control: (provided: any) => ({
        ...provided,
        width: '100%', // Ensure the control element also takes full width of the container
    }),
    menu: (provided: any) => ({
        ...provided,
        width: '100%', // Ensure the dropdown menu matches the width of the control
    }),
};
function getPositionRelativeToDiagonal(rect: any, point: any) {
    // Extract coordinates of the rectangle and the point
    const { x1, y1, x2, y2 } = rect; // Rectangle corners (top-left, bottom-right)
    const { x, y } = point; // Point coordinates

    // Vector from top-right (x2, y1) to bottom-left (x1, y2)
    const vectorBCx = (x1 - x2);
    const vectorBCy = (y2 - y1);

    // Vector from top-right (x2, y1) to point (x, y)
    const vectorBPx = x - x2;
    const vectorBPy = y - y1;

    // Calculate the 2D cross product
    const crossProduct = (vectorBCx * vectorBPy) - (vectorBCy * vectorBPx);

    // Determine the position based on the sign of the cross product
    return crossProduct
}


function compareArrays(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) {
        return false; // If lengths are different, arrays are not equal
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false; // If any element is different, arrays are not equal
        }
    }

    return true; // Arrays are equal
}

const new_order_fn = (tmp_order: any, tmp_useraireports: any, tmp_schoolaireports: any) => {
    try {
        const tmp_set: any = new Set([
            ...tmp_order,
            ...tmp_useraireports?.filter((x: any) => x?.order_id)?.map((x: any) => x?.order_id),
            ...tmp_schoolaireports?.filter((x: any) => x?.order_id)?.map((x: any) => x?.order_id),
        ]);
        const tmp_set_arr = [...(tmp_set ?? [])];
        return tmp_set_arr;
    } catch (error) {
        return [];
    }
}
const get_btns_from_order_fn = (aireportsuserorder: any, useraireports: any, schoolaireports: any) => {

    const tmp_aireportsuserorder: any = [];
    for (let i = 0; i < aireportsuserorder?.length; i++) {
        const aireport_order_id: string = aireportsuserorder[i];
        let aireport_title = null;
        switch (aireport_order_id?.charAt(0)) {
            case 's':
                aireport_title = schoolaireports?.find((t: any) => aireport_order_id === t?.order_id);
                break;

            case 'u':
                aireport_title = useraireports?.find((t: any) => aireport_order_id === t?.order_id);
                break;

            default:
                break;
        }
        if (!aireport_title) {
            continue;
        }
        tmp_aireportsuserorder?.push(aireport_title);
    }
    return tmp_aireportsuserorder;
}
const Create_New_Report = "Create New Report";
const ViewStudent = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const { id } = useParams<{ id?: string }>();
    // // // // console.log("id");
    // // // // console.log(id);
    const tabArr = [
        { value: homeTab, label: "Home", link: null },
        { value: "progress", label: "Progress", link: `${process.env.REACT_APP_LMS}/studentprogress/${id}` },
        { value: "attendance", label: "Attendance", link: `${process.env.REACT_APP_LMS}/sattendances/${id}` },
        { value: "Performance", label: "Performance", link: `${process.env.REACT_APP_LMS}/students/${id}` },
    ];
    const students = useSelector((state: any) => state.Student.students);
    const student = students.find((st: any) => st.id == id);
    const guardian = useSelector((state: any) => state.Parent.guardian);
    const t = new Translate()
    const schoologyUserName: any = useSelector(
        (state: any) => state.Parent.schoologyUserName
      );

    const [selectedTab, setSelectedTab] = useState(homeTab);
    const [selectedLink, setSelectedLink] = useState<any>(null);
    const [value, setValue] = useState(0);
    const [schoolaireports, setSchoolaireports] = useState<any>([]);
    const [useraireports, setUseraireports] = useState<any>([]);
    const [aireportsuserorder, setAireportsuserorder] = useState<any>([]);
    const [customised_arr, setCustomisedArr] = useState<any>([]);
    const [newTitle, setNewTitle] = useState("");
    const [prompt_sentence, setPrompt_sentence] = useState('');
    const [prompt_icon, setPrompt_icon] = useState('');
    const [homeModalLink, setHomeModalLink] = useState<any>(null);
    const [textModalHeader, setTextModalHeader] = useState("");
    const [flagModalIframe, setFlagModalIframe] = useState(false)
    const [flagBtnsEdit, setFlagBtnsEdit] = useState(false);
    const [flagModalBtnsCreateNew, setFlagModalBtnsCreateNew] = useState(false);
    const divRef = useRef<(HTMLDivElement | null)>(null);
    const containerRef = useRef<(HTMLDivElement | null)>(null);
    const [draggedOverThisOrderId, setDraggedOverThisOrderId] = useState<any>(null);
    const [draggedOverThisOrderIdAfterFlag, setDraggedOverThisOrderIdAfterFlag] = useState<boolean>(false);

    const saveModalBtnsEdit = () => {
        // // // console.log("customised_arr");
        // // // console.log(customised_arr);
        Swal?.showLoading();
        axios?.post(
            `${process.env.REACT_APP_BASEURL}student/saveaireportsuserorder.php`,
            {
                order_arr: customised_arr,
            }
        )
            ?.then((response: any) => {
                const response_data_data = response?.data?.data;

                const tmp_flag = response_data_data?.flag;
                if (!tmp_flag) {
                    throw new Error("Something went wrong!");
                }
                setAireportsuserorder(customised_arr);
                Swal.fire(t.translate("Saved Successfully"), "", "success");

            })
            ?.catch((error: any) => {
                Swal.fire(t.translate(error?.response?.data.msg || error.message || "Failed"), "", "error");
            });
    }
    // Handle the start of the drag event
    const handleDragStart = (e: any, item: any, sourceArr: any) => {
        const order_id = item.order_id;
        setDraggedOverThisOrderId(null);
        // handleRemoveItem(order_id);
        // Storing the dragged item and its source array in the dataTransfer object
        e.dataTransfer.setData('order_id', order_id); // Store only the order_id
        e.dataTransfer.setData('sourceArr', sourceArr); // Storing the source of the dragged item (schoolaireports or useraireports)
    };

    // Handle the drop event when an item is dropped in the customized box
    const handleDrop = (e: any) => {
        e.preventDefault(); // Prevent default behavior of the drop action
        // // // // console.log("handleDrop");
        // // // // console.log(e);
        // Get the data from the drag event (the order_id and source array)
        const order_id = e.dataTransfer.getData('order_id');
        let arr = [...(customised_arr ?? [])];
        const len = arr?.length ?? 0;
        let j = arr?.indexOf(order_id);
        if (j != -1) {
            arr = (arr ?? [])?.filter((id: any) => id !== order_id);
        }

        let index = arr?.indexOf(draggedOverThisOrderId);

        if (index === -1) {

            const containerRef_current = containerRef?.current;
            const containerRefRect = containerRef_current?.getBoundingClientRect();
            // // console.log("containerRefRect");
            // // console.log(containerRefRect);
            const clientX = e?.clientX;
            // // console.log("clientX");
            // // console.log(clientX);
            const clientY = e?.clientY;
            // // console.log("clientY");
            // // console.log(clientY);
            if (containerRefRect) {
                const rect = { x1: containerRefRect?.left, y1: containerRefRect?.top, x2: containerRefRect?.right, y2: containerRefRect?.bottom };
                const point = { x: clientX, y: clientY };
                let b4_flag;
                if (3 > len) {
                    // flagX && flagY -> means the element is dropped inside the first quarter
                    const flagX = (clientX <= (containerRefRect?.right / 2));
                    // console.log("flagX");
                    // console.log(flagX);
                    const flagY = (clientY >= (containerRefRect?.top / 2))
                    // console.log("flagY");
                    // console.log(flagY);
                    b4_flag = flagX && flagY;

                } else {
                    b4_flag = getPositionRelativeToDiagonal(rect, point) > 0;
                }

                // console.log("b4_flag");
                // console.log(b4_flag);
                if (b4_flag) {
                    arr = [order_id, ...(arr ?? [])];
                } else {
                    arr?.push(order_id);
                }
            } else {
                arr?.push(order_id);
            }
        } else {
            arr?.splice(index + (draggedOverThisOrderIdAfterFlag as any), 0, order_id);
        }


        setCustomisedArr(arr);
        setDraggedOverThisOrderId(null);
    };

    // Handle the removal of an item from the customised box
    const handleRemoveItem = (order_id: any) => {
        // Remove the order_id from customised_arr
        setCustomisedArr(customised_arr.filter((id: any) => id !== order_id));
    };

    //   // Filter out items that are already in customised_arr from schoolaireports and useraireports
    //   const filteredSArr = schoolaireports.filter((item:any) => !customised_arr.includes(item.order_id));
    //   const filteredUArr = useraireports.filter((item:any) => !customised_arr.includes(item.order_id));

    const loadThisLink = (link: string) => {
        Swal?.showLoading();
        setSelectedLink(link);
        setHomeModalLink(null);
    }
    useEffect(() => {

        if (homeTab === selectedTab) {
            Swal?.showLoading();
            axios.get(`${process.env.REACT_APP_BASEURL}student/getreportslib.php`)
                ?.then((response: any) => {
                    const response_data_data = response?.data?.data;
                    const tmp_schoolaireports = response_data_data?.schoolaireports;
                    if (!tmp_schoolaireports) {
                        throw new Error("Something went wrong!");
                    }
                    setSchoolaireports(tmp_schoolaireports);

                    const tmp_useraireports = response_data_data?.useraireports;
                    if (!tmp_useraireports) {
                        throw new Error("Something went wrong!");
                    }
                    setUseraireports(tmp_useraireports);

                    if (!response_data_data?.aireportsuserorder?.order) {
                        throw new Error("Something went wrong!");
                    }
                    const tmp_order = JSON.parse(response_data_data?.aireportsuserorder?.order)?.order;
                    // // // // // console.log("tmp_order");
                    // // // // // console.log(tmp_order);
                    if (!(tmp_order)) {
                        throw new Error("Something went wrong!");
                    }
                    setAireportsuserorder(tmp_order);

                    Swal?.close();
                })
                ?.catch((error: any) => {
                    // // // // // // console.log(error.message);
                    Swal.fire(t.translate(error?.response?.data.msg || error.message || "Failed"), "", "error");
                })
        }
    }, [selectedTab])
    const setLms = ()=>{
   
        axios.post(`${process.env.REACT_APP_LMS_BASURL}include/store_parent_token.php`,
        {
    
          username: schoologyUserName,
          emaildomain: "ivyis.org",
          report : true
        })
      }
    const handleChangeClub = (e: any, student_id: any) => {
        Swal.showLoading();
        axios
            .post(`${process.env.REACT_APP_BASEURL}student/setclub.php`, {
                student_id: student_id,
                club_id: e.value,
            }).then(() => {
                Swal.fire(t.translate('Success'), t.translate('Club Assigned successfully'), 'success').then(() => {
                    dispatch(StudnetCreator.GetStudents({ id: guardian.id }));
                    Swal.close();
                })
            }).catch((error) => {
                console.log(error.response.data.msg);
                Swal.fire(t.translate('Failed to assign club'), t.translate(error.response.data.msg), 'error').then(() => {
                    dispatch(StudnetCreator.GetStudents({ id: guardian.id }));
                    Swal.close();
                })
            })

    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (!student) {
        return (
            <div>
                {t.translate("Loading")}
            </div>
        )
    }
    return (
        <div className="row">
            <Card className="w-100">
                <CardBody className="all-icons">


                    <Box sx={{ width: "100%", backgroundColor: "#fff" }}>
                        <Box
                            sx={{
                                minHeight: "28vh",
                                backgroundColor: "#0c50a7",
                                padding: "2rem",
                                borderRadius: "12px",
                                marginBottom: "20px",
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                display="flex"
                                justifyContent="space-around"
                                sx={{}}
                                className=" flex-md-row-reverse"
                            >
                                <Grid
                                    item
                                    sm={12}
                                    md={6}
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <div>
                                        <img
                                            src={student?.driveid?.includes("https://") ? student?.driveid : `https://drive.google.com/uc?id=${student?.driveid}&export=download`}
                                            alt={"..."}
                                            style={{ maxWidth: "90%", minWidth: "360px", borderRadius: "50%", height: "367px", filter: "drop-shadow(0px 1px 2px #fff)" }}

                                        />
                                    </div>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <Typography variant="h3" sx={{ color: "#f3af58" }} gutterBottom>
                                        {student?.fullname}
                                    </Typography>
                                    <Typography variant="h4" sx={{ color: "#fff" }} gutterBottom>
                                        {t.translate("Student Id")} :{" "}
                                        <span style={{ fontSize: "24px" }}>{student?.id}</span>
                                    </Typography>
                                    <Typography variant="h4" sx={{ color: "#fff" }} gutterBottom>
                                        {t.translate("Grade")} :{" "}
                                        <span style={{ fontSize: "24px" }}>
                                            {student?.grade}
                                        </span>
                                    </Typography>
                                    <div className=" " key={student.id}>
                                        <div className="   mb-5 ">
                                            <br />

                                            <Typography variant="h5" sx={{ color: "#fff" }} gutterBottom>
                                                {t.translate("Reinforcement points")}

                                            </Typography>

                                            <Link
                                                className=" mb-5"
                                                to={`/dashboard/viewstudents/${student.id}/Reinforcement-point`}
                                            >
                                                <Line color="blue" Reinforcements={student.Reinforcements} />
                                            </Link>

                                            <Typography variant="h5" className='mt-5' sx={{ color: "#fff" }} gutterBottom>
                                                {t.translate("Preferred Club")}

                                            </Typography>
                                          
                                                <Select
                                                    options={student.clubs}
                                                    defaultValue={student.clubs.find(
                                                        (i: any) => i.value == student.club
                                                    )}
                                                    onChange={(e) => {
                                                        handleChangeClub(e, student.id);
                                                    }}
                                                    isDisabled={
                                                        format(new Date(), "yyyy-MM-dd") >= student.lockeddate ||
                                                        !student.lockeddate
                                                    }
                                                />
                                           
                                            <div className="mt-5">
                                               
                                                <Link
                                                    className="btn btn-success text-center fw-bold"
                                                    to={`/dashboard/viewstudents/${student.id}/progress-report`}
                                                >
                                                    {t.translate("Progress Card")}
                                                </Link>

                                                <a
                                                    target="_blank"
                                                    href={`https://student.ivyis.org/backend/store.php?jwt=${student.jwt}`}
                                                    className="btn btn-primary text-center fw-bold my-3 mx-md-2 text-light"
                                                >
                                                    {t.translate("Profile")}
                                                </a>
                                                <Link
                                                    className="btn btn-info text-center fw-bold my-3 "
                                                    to={`/dashboard/viewstudents/${student.id}/map-growth`}
                                                >
                                                    {t.translate("Map Growth")}
                                                </Link>
                                                {student.grade_id < "5" ? (
                                                    <Link
                                                        className="btn btn-warning my-3 mx-md-2 text-center fw-bold"
                                                        to={`/dashboard/classactivities/${student.id}`}
                                                    >
                                                        {t.translate("Daily Activities")}
                                                    </Link>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>



                            </Grid>

                        </Box>

                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {
                                    tabArr?.map((tabArr_child: any, i: any) => {
                                        // // // // // // console.log("route_child")
                                        // // // // // // console.log(route_child)
                                        return <Tab
                                            key={tabArr_child.value}
                                            value={i}
                                            onClick={() => {
                                                // // // // console.log("tabArr_child?.value");
                                                // // // // console.log(tabArr_child?.value);
                                                setLms();
                                                setSelectedTab(tabArr_child?.value);
                                                loadThisLink(tabArr_child?.link);
                                                setFlagBtnsEdit(false);

                                            }}
                                            label={t.translate(tabArr_child.label)}
                                            {...a11yProps(i)}
                                        />
                                    }
                                    )
                                }
                            </Tabs>
                        </Box>
                    </Box>
                    <div
                        className=' w-100'
                        style={{
                            // minHeight: "1000px",
                        }}
                    >
                        {
                            (homeTab === selectedTab) ?
                                (
                                    flagBtnsEdit ?

                                        (
                                            <>

                                                {
                                                    (() => {
                                                        const compare_flag = compareArrays(aireportsuserorder, customised_arr);
                                                        return (
                                                            <div
                                                                className=' row align-items-stretch '
                                                            >
                                                                <div
                                                                    className='col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start align-items-stretch mt-4  '
                                                                >
                                                                    <button
                                                                        className=' btn-primary btn '
                                                                        onClick={() => {
                                                                            setFlagModalBtnsCreateNew(true);
                                                                            setNewTitle("");
                                                                            setPrompt_sentence("");
                                                                            setPrompt_icon("");
                                                                        }}
                                                                    >
                                                                        {t.translate(Create_New_Report)}
                                                                    </button>
                                                                </div>
                                                                <div
                                                                    className=' col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end align-items-stretch gap-3 mt-4 '
                                                                >
                                                                    <button
                                                                        className=' btn-primary btn w-50 '
                                                                        disabled={compare_flag}
                                                                        onClick={() => {
                                                                            Swal.fire({
                                                                                title: t.translate('Are you sure?'),
                                                                                text: "",
                                                                                icon: 'question',
                                                                                showCancelButton: false, // Show the Cancel button
                                                                                confirmButtonText: t.translate('Save changes'), // Text for the "Save changes" button
                                                                                // cancelButtonText: t.translate('Close without saving'), // Text for the "Close" button
                                                                                showDenyButton: true, // Show the "Cancel" button
                                                                                denyButtonText: t.translate('Cancel'), // Text for the "Cancel" button
                                                                                // reverseButtons: true, // Reverse the button order (Save -> Cancel -> Close)
                                                                            }).then((result) => {
                                                                                if (result.isConfirmed) {
                                                                                    // If "Save changes" is clicked
                                                                                    // // // console.log('Saving changes...');
                                                                                    saveModalBtnsEdit();
                                                                                } else if (result.isDenied) {
                                                                                    // If "Cancel" is clicked
                                                                                    // // // console.log('Action cancelled');
                                                                                    //   do nothing
                                                                                    // } else if (result.isDismissed) {
                                                                                    //     // If "Close without saving" is clicked or the modal is dismissed
                                                                                    //     // // // console.log('Closing without saving');
                                                                                    //     setFlagBtnsEdit(false);
                                                                                }
                                                                            });
                                                                        }}
                                                                    >
                                                                        {t.translate("Save")}
                                                                    </button>
                                                                    <button
                                                                        className=' btn-danger btn '
                                                                        onClick={() => {
                                                                            if (compare_flag) {
                                                                                setFlagBtnsEdit(false);
                                                                            } else {

                                                                                Swal.fire({
                                                                                    title: t.translate('You have unsaved changes!'),
                                                                                    text: t.translate('Do you want to save your changes before closing?'),
                                                                                    icon: 'warning',
                                                                                    showCancelButton: true, // Show the Cancel button
                                                                                    confirmButtonText: t.translate('Save changes'), // Text for the "Save changes" button
                                                                                    cancelButtonText: t.translate('Cancel'), // Text for the "Cancel" button
                                                                                    showDenyButton: true, // Show the "Cancel" button
                                                                                    denyButtonText: t.translate('Close without saving'), // Text for the "Close" button
                                                                                    // reverseButtons: true, // Reverse the button order (Save -> Cancel -> Close)
                                                                                }).then((result) => {
                                                                                    if (result.isConfirmed) {
                                                                                        // If "Save changes" is clicked
                                                                                        // // // console.log('Saving changes...');
                                                                                        saveModalBtnsEdit();
                                                                                    } else if (result.isDenied) {
                                                                                        // If "Close without saving" is clicked or the modal is dismissed
                                                                                        // // // console.log('Closing without saving');
                                                                                        setFlagBtnsEdit(false);
                                                                                    } else if (result.isDismissed) {
                                                                                        // If "Cancel" is clicked
                                                                                        // // // console.log('Action cancelled');
                                                                                        //   do nothing
                                                                                    }
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        {t.translate("Return")}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })()
                                                }
                                                <div className=" ">
                                                    <div className="row ">
                                                        <div className="col-12  col-sm-6 mt-4">
                                                            <div
                                                                className=" p-3"
                                                            >
                                                                <h5
                                                                    className=" px-3"
                                                                >{t.translate("Customised Library")}</h5>

                                                                <div
                                                                    className="border px-3"
                                                                    style={{
                                                                        borderRadius: "10px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        id="customised_box"
                                                                        className='  py-1 '
                                                                        onDragOver={(e) => {
                                                                            // Allow drop by preventing default behavior
                                                                            e.preventDefault();
                                                                            // // // // console.log("onDragOver");
                                                                            // // // // console.log(e);
                                                                            // // // // console.log(e?.target);
                                                                            const clientX = e?.clientX;
                                                                            // // // // console.log("clientX");
                                                                            // // // // console.log(clientX);
                                                                            // const clientY = e?.clientY;
                                                                            // // // // console.log("clientY");
                                                                            // // // // console.log(clientY);
                                                                            const divRef_current = divRef?.current;
                                                                            const divRefRect = divRef_current?.getBoundingClientRect();
                                                                            // // // console.log("divRefRect");
                                                                            // // // console.log(divRefRect);
                                                                            if (divRefRect) {
                                                                                const divRefRect_center = (divRefRect?.width / 2) + divRefRect?.left;
                                                                                // // // console.log("divRefRect_center");
                                                                                // // // console.log(divRefRect_center);
                                                                                const AfterFlag = clientX > divRefRect_center;
                                                                                // // // console.log("AfterFlag");
                                                                                // // // console.log(AfterFlag);
                                                                                setDraggedOverThisOrderIdAfterFlag(AfterFlag);

                                                                            }
                                                                        }}
                                                                        onDrop={(e) => handleDrop(e)} // Handle drop event
                                                                        ref={containerRef}
                                                                    >

                                                                        <div className="row "  >
                                                                            {
                                                                                (() => {
                                                                                    const tmp_aireportsuserorder: any = get_btns_from_order_fn(customised_arr, useraireports, schoolaireports);
                                                                                    return tmp_aireportsuserorder?.map((aireport: any) => {
                                                                                        const aireport_title = aireport?.title ?? "";
                                                                                        const aireport_prompt_sentence = aireport?.prompt_sentence ?? "";
                                                                                        const aireport_prompt_icon = aireport?.prompt_icon ?? "";
                                                                                        const tmp_name = t.translate(aireport_title);
                                                                                        const tmp_prompt_sentence = t.translate(aireport_prompt_sentence);
                                                                                        const order_id = aireport?.order_id;
                                                                                        const isDraggedOverThis = (order_id === draggedOverThisOrderId);
                                                                                        // const brdr_txt_primary_clr = `${brdr_txt} ${primary_clr}`;
                                                                                        const brdr_txt_primary_clr = `${brdr_txt} transparent`;
                                                                                        const btn_style: any = {
                                                                                            border: brdr_txt_primary_clr,
                                                                                            borderRight: brdr_txt_primary_clr,
                                                                                            borderLeft: brdr_txt_primary_clr,
                                                                                        };
                                                                                        if (isDraggedOverThis) {
                                                                                            const prop_name1 = `border${draggedOverThisOrderIdAfterFlag ? "Right" : "Left"}`
                                                                                            // const prop_name2 = `border${!draggedOverThisOrderIdAfterFlag ? "Right" : "Left"}`
                                                                                            btn_style[prop_name1] = `${brdr_txt} yellow`;
                                                                                            // btn_style[prop_name2] = brdr_txt_primary_clr;
                                                                                        }
                                                                                        return (
                                                                                            <div className=" col-12 col-md-6 col-xl-4   pt-3 pb-1 d-flex justify-content-center align-items-stretch "
                                                                                                draggable
                                                                                                // Attach the drag start event
                                                                                                onDragStart={(e) => handleDragStart(e, aireport, '')}
                                                                                                onDragEnd={(e: any) => {
                                                                                                    e?.preventDefault();
                                                                                                    // // console.log("onDragEnd");
                                                                                                    // // console.log(e);
                                                                                                    setDraggedOverThisOrderId(null);

                                                                                                    const clientX = e?.clientX;
                                                                                                    // // console.log("clientX");
                                                                                                    // // console.log(clientX);
                                                                                                    const clientY = e?.clientY;
                                                                                                    // // console.log("clientY");
                                                                                                    // // console.log(clientY);

                                                                                                    let should_delete = true;
                                                                                                    const containerRef_current = containerRef?.current;
                                                                                                    const containerRefRect = containerRef_current?.getBoundingClientRect();
                                                                                                    // // console.log("containerRefRect");
                                                                                                    // // console.log(containerRefRect);
                                                                                                    if (containerRefRect) {
                                                                                                        // flagX && flagY -> means the element is dropped inside
                                                                                                        const flagX = (clientX >= containerRefRect?.left) && (clientX <= containerRefRect?.right);
                                                                                                        // // console.log("flagX");
                                                                                                        // // console.log(flagX);
                                                                                                        const flagY = (clientY >= containerRefRect?.top) && (clientY <= containerRefRect?.bottom);
                                                                                                        // // console.log("flagY");
                                                                                                        // // console.log(flagY);
                                                                                                        should_delete = !(flagX && flagY);
                                                                                                    }
                                                                                                    // // console.log("should_delete");
                                                                                                    // // console.log(should_delete);
                                                                                                    if (should_delete) {
                                                                                                        handleRemoveItem(order_id);
                                                                                                    }
                                                                                                }}
                                                                                                onDragOver={(e) => {
                                                                                                    // Allow drop by preventing default behavior
                                                                                                    e.preventDefault();
                                                                                                    // // // // console.log("onDragOver");
                                                                                                    // // // // console.log(e);
                                                                                                    // // // // console.log(e?.target);
                                                                                                    // const clientX = e?.clientX;
                                                                                                    // // // // console.log("clientX");
                                                                                                    // // // // console.log(clientX);
                                                                                                    // const clientY = e?.clientY;
                                                                                                    // // // // console.log("clientY");
                                                                                                    // // // // console.log(clientY);
                                                                                                    // // // // console.log("order_id");
                                                                                                    // // // // console.log(order_id);
                                                                                                    setDraggedOverThisOrderId(order_id);

                                                                                                }}
                                                                                                onDragLeave={(e) => {
                                                                                                    // Allow drop by preventing default behavior
                                                                                                    e.preventDefault();
                                                                                                    // // // // console.log("onDragOver");
                                                                                                    // // // // console.log(e);
                                                                                                    // // // // console.log(e?.target);
                                                                                                    // const clientX = e?.clientX;
                                                                                                    // // // // console.log("clientX");
                                                                                                    // // // // console.log(clientX);
                                                                                                    // const clientY = e?.clientY;
                                                                                                    // // // // console.log("clientY");
                                                                                                    // // // // console.log(clientY);
                                                                                                    // // // // console.log("order_id");
                                                                                                    // // // // console.log(order_id);
                                                                                                    setDraggedOverThisOrderId(null);

                                                                                                }}
                                                                                                key={order_id}
                                                                                                ref={isDraggedOverThis ? divRef : null}
                                                                                            >
                                                                                                <div
                                                                                                    key={order_id}
                                                                                                    style={{
                                                                                                        position: `relative`
                                                                                                    }}
                                                                                                    className=" w-100 d-flex justify-content-between align-items-stretch  mb-2"
                                                                                                >
                                                                                                    {/* Display order_id as button in the customised box */}
                                                                                                    <button
                                                                                                        className=" w-100 btn btn-primary  "
                                                                                                        title={tmp_prompt_sentence}
                                                                                                        style={btn_style}
                                                                                                    >
                                                                                                        <i className={`fas fa-${aireport_prompt_icon}`}></i>
                                                                                                        <span className=" pl-2 ">{tmp_name}</span>
                                                                                                    </button>
                                                                                                    {/* Button to remove the item from the customised box */}
                                                                                                    <button
                                                                                                        className=" btn btn-danger p-0 m-0"
                                                                                                        style={{
                                                                                                            position: "absolute",
                                                                                                            borderRadius: `50%`,
                                                                                                            right: `-${1 * redXsize}px`,
                                                                                                            top: `-${1 * redXsize}px`,
                                                                                                            width: `${2 * redXsize}px`,
                                                                                                            height: `${2 * redXsize}px`,
                                                                                                            fontSize: `${1 * redXsize}px`,
                                                                                                        }}
                                                                                                        onClick={() => handleRemoveItem(order_id)} // Remove item from customised box
                                                                                                    >
                                                                                                        X
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12  col-sm-6 mt-4">
                                                            <div
                                                                className=" p-3"
                                                            >
                                                                <h5
                                                                    className=" px-3"
                                                                >{t.translate("Standard reports")}</h5>

                                                                <div
                                                                    className="border px-3"
                                                                    style={{
                                                                        borderRadius: "10px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        id="s_box"
                                                                        className='  py-1 '
                                                                    >
                                                                        <div className="row">
                                                                            {
                                                                                schoolaireports.filter((item: any) => !customised_arr?.includes(item?.order_id))?.map((aireport: any) => {

                                                                                    const aireport_title = aireport?.title ?? "";
                                                                                    const aireport_prompt_sentence = aireport?.prompt_sentence ?? "";
                                                                                    const aireport_prompt_icon = aireport?.prompt_icon ?? "";
                                                                                    const tmp_name = t.translate(aireport_title);
                                                                                    const tmp_prompt_sentence = t.translate(aireport_prompt_sentence);
                                                                                    const order_id = aireport?.order_id;


                                                                                    const brdr_txt_primary_clr = `${brdr_txt} transparent`;
                                                                                    const btn_style: any = {
                                                                                        border: brdr_txt_primary_clr,
                                                                                    };
                                                                                    return (
                                                                                        <div
                                                                                            className=" col-12 col-md-6 col-xl-4   pt-3 pb-1 d-flex justify-content-center align-items-stretch "
                                                                                            key={order_id}
                                                                                        >
                                                                                            <div
                                                                                                key={order_id}
                                                                                                style={{
                                                                                                    position: `relative`
                                                                                                }}
                                                                                                className=" w-100 d-flex justify-content-between align-items-stretch  mb-2"
                                                                                            >
                                                                                                {/* Display order_id as button in the customised box */}
                                                                                                <button
                                                                                                    className=" w-100 btn btn-primary  "
                                                                                                    title={tmp_prompt_sentence}
                                                                                                    style={btn_style}
                                                                                                    draggable
                                                                                                    onDragEnd={(e: any) => {
                                                                                                        e?.preventDefault();
                                                                                                        setDraggedOverThisOrderId(null);
                                                                                                    }}
                                                                                                    // Attach the drag start event
                                                                                                    onDragStart={(e) => handleDragStart(e, aireport, 'schoolaireports')}
                                                                                                >
                                                                                                    <i className={`fas fa-${aireport_prompt_icon}`}></i>
                                                                                                    <span className=" pl-2 ">{tmp_name}</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                                <Modal
                                                    isOpen={flagModalBtnsCreateNew}
                                                    className="modal-dialog-centered"
                                                >
                                                    <div
                                                        className=" p-3 d-flex align-items-center   justify-content-between  "
                                                    >
                                                        <h1
                                                            className=" p-0 m-0 "
                                                        >
                                                            {t.translate(Create_New_Report)}
                                                        </h1>
                                                        <div
                                                            className='d-flex gap-3'
                                                        >
                                                            <button
                                                                className=' btn-danger btn '
                                                                onClick={() => {
                                                                    setFlagModalBtnsCreateNew(false);
                                                                }}
                                                            >
                                                                X
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <ModalBody>

                                                        <div className="row mt-4 ">
                                                            <div className=" gap-2 col-12 col-sm-2 col-md-1 mb-3 d-flex justify-start  align-items-center ">
                                                                <span
                                                                    style={{
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {
                                                                        t.translate("Title :")
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className=" gap-2 col-12 col-sm-10 col-md-5 mb-3 d-flex justify-start  align-items-center ">
                                                                <input
                                                                    type="text"
                                                                    className=' form-control '
                                                                    onChange={(e: any) => {
                                                                        setNewTitle(e?.target?.value ?? "");
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className=" gap-2 col-12 col-sm-2 col-md-1 mb-3 d-flex justify-start  align-items-center ">
                                                                <span
                                                                    style={{
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {
                                                                        t.translate("Icon :")
                                                                    }
                                                                </span>

                                                            </div>
                                                            <div className=" gap-2 col-12 col-sm-10 col-md-5 mb-3 d-flex justify-start  align-items-center ">
                                                                <Select
                                                                    options={icon_arr?.map((ico: any) => ({ value: ico, label: (<i className={`fas fa-${ico}`}></i>) }))}
                                                                    onChange={((e: any) => {
                                                                        // // // console.log(e?.value ?? "");
                                                                        setPrompt_icon(e?.value ?? "");
                                                                    })}
                                                                    value={prompt_icon ? ({ value: prompt_icon, label: (<i className={`fas fa-${prompt_icon}`}></i>) }) : null}
                                                                    styles={customStyles}
                                                                />
                                                            </div>
                                                            <div className=" gap-2 col-12  col-sm-2  col-md-1 mb-3 d-flex justify-start  align-items-center ">
                                                                <span
                                                                    style={{
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {
                                                                        t.translate("Prompt:")
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="  col-12 col-sm-10 col-md-10  col-lg-10 col-xl-10  mb-3 d-flex justify-start  align-items-center  ">
                                                                <div
                                                                    className='w-100'
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        className=' form-control '
                                                                        onChange={(e: any) => {
                                                                            setPrompt_sentence(e?.target?.value ?? "");
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <button
                                                                className='btn btn-primary col-12 col-sm-12 col-md-1  mb-3 '
                                                                disabled={!newTitle}
                                                                onClick={() => {
                                                                    Swal?.showLoading();
                                                                    // // // // console.log("id in button");
                                                                    // // // // console.log(id);
                                                                    axios?.post(
                                                                        `${process.env.REACT_APP_BASEURL}student/saveuseraireport.php`,
                                                                        {
                                                                            title: newTitle,
                                                                            prompt_sentence,
                                                                            prompt_icon,
                                                                        }
                                                                    )
                                                                        ?.then((response: any) => {
                                                                            const response_data_data = response?.data?.data;

                                                                            const tmp_useraireport_id = response_data_data?.useraireport_id;
                                                                            if (!tmp_useraireport_id) {
                                                                                throw new Error("Something went wrong!");
                                                                            }
                                                                            const new_order_id = `u_${tmp_useraireport_id}`;
                                                                            const tmp_useraireports = [...(useraireports ?? [])];
                                                                            tmp_useraireports?.push({
                                                                                order_id: new_order_id,
                                                                                title: newTitle,
                                                                                prompt_sentence,
                                                                                prompt_icon,
                                                                            });
                                                                            setUseraireports(tmp_useraireports);
                                                                            setAireportsuserorder((x: any) => [...x, new_order_id]);
                                                                            setCustomisedArr((x: any) => [...x, new_order_id]);
                                                                            setFlagModalBtnsCreateNew(false);
                                                                            Swal.fire(t.translate("Saved Successfully"), "", "success");

                                                                        })
                                                                        ?.catch((error: any) => {
                                                                            Swal.fire(t.translate(error?.response?.data.msg || error.message || "Failed"), "", "error");
                                                                        });
                                                                }}
                                                            >
                                                                {t.translate("Save")}
                                                            </button>

                                                        </div>


                                                    </ModalBody>
                                                </Modal>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <div
                                                    className=" p-3 d-flex align-items-center   justify-content-between  "
                                                >
                                                    {

                                                        <h6
                                                            className=" p-0 m-0 "
                                                        >
                                                            {t.translate("You can edit these reports by clicking this button")}
                                                        </h6>
                                                    }
                                                    <div>
                                                        <button
                                                            className=' btn-primary btn '
                                                            onClick={() => {
                                                                setFlagBtnsEdit(true);
                                                                setCustomisedArr(aireportsuserorder);
                                                            }}
                                                        >
                                                            {t.translate("Edit")}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        (() => {
                                                            const tmp_aireportsuserorder: any = get_btns_from_order_fn(aireportsuserorder, useraireports, schoolaireports);
                                                            return (
                                                                <>
                                                                    {
                                                                        tmp_aireportsuserorder?.map((aireport: any) => {
                                                                            const aireport_title = aireport?.title ?? "";
                                                                            const aireport_prompt_sentence = aireport?.prompt_sentence ?? "";
                                                                            const tmp_name = t.translate(aireport_title);
                                                                            const aireport_prompt_icon = aireport?.prompt_icon ?? "";
                                                                            const tmp_prompt_sentence = t.translate(aireport_prompt_sentence);
                                                                            const order_id = aireport?.order_id;
                                                                            return (
                                                                                <div
                                                                                    className=" col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3 d-flex justify-content-center align-items-stretch "
                                                                                    key={order_id}
                                                                                >
                                                                                    <button
                                                                                        className=' w-100 btn btn-primary py-2 '
                                                                                        onClick={() => {
                                                                                            // // // // console.log("id in button");
                                                                                            // // // // console.log(id);
                                                                                            const tmp_link = `${process.env.REACT_APP_LMS}/requests/${encodeURIComponent(aireport_prompt_sentence)}/students/${id}`;
                                                                                            // // // // console.log("tmp_link");
                                                                                            // // // // console.log(tmp_link);
                                                                                            setHomeModalLink(tmp_link);
                                                                                            setTextModalHeader(tmp_name);
                                                                                            setFlagModalIframe(true);
                                                                                            Swal?.showLoading();
                                                                                        }}
                                                                                        title={tmp_prompt_sentence}
                                                                                    >
                                                                                        <i className={`fas fa-${aireport_prompt_icon}`}></i>
                                                                                        <span className=" pl-2 ">{tmp_name}</span>
                                                                                    </button>
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                    {
                                                                        homeModalLink ?
                                                                            (
                                                                                <Modal isOpen={flagModalIframe} >
                                                                                    <div
                                                                                        className=" p-3 d-flex align-items-center   justify-content-between  "
                                                                                    >
                                                                                        <h1
                                                                                            className=" p-0 m-0 "
                                                                                        >
                                                                                            {textModalHeader}
                                                                                        </h1>
                                                                                        <div>
                                                                                            <button
                                                                                                className=' btn-danger btn '
                                                                                                onClick={() => {
                                                                                                    setFlagModalIframe(false);
                                                                                                }}
                                                                                            >
                                                                                                X
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ModalBody>
                                                                                        <iframe
                                                                                            className=' w-100'
                                                                                            style={{
                                                                                                height: "100%",
                                                                                                minHeight: "1000px",
                                                                                            }}
                                                                                            src={homeModalLink}
                                                                                            onLoad={() => {
                                                                                                // // // // console.log("iframe loaded");
                                                                                                Swal?.close();
                                                                                            }}
                                                                                            onError={() => {
                                                                                                Swal.fire(t.translate("Failed"), "", "error");
                                                                                            }}
                                                                                        />

                                                                                    </ModalBody>
                                                                                </Modal>
                                                                            )
                                                                            :
                                                                            null
                                                                    }

                                                                </>
                                                            )
                                                        })()
                                                    }
                                                </div>
                                            </>
                                        )
                                )
                                :
                                null
                        }
                        {
                            selectedLink ?
                                (
                                    <iframe
                                        className=' w-100'
                                        style={{
                                            height: "100%",
                                            minHeight: "1000px",
                                        }}
                                        src={selectedLink}
                                        onLoad={() => {
                                            // // // // console.log("iframe loaded");
                                            Swal?.close();
                                        }}
                                        onError={() => {
                                            Swal.fire(t.translate("Failed"), "", "error");
                                        }}
                                    />
                                )
                                :
                                null
                        }
                    </div>
                </CardBody>
            </Card>
        </div >


    );
}

export default ViewStudent